<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'onboarding-questions' }"
        >
          Onboarding Questions
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <question-form :questionId="this.$route.params.id" @submit="submit" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiChevronLeft } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import QuestionForm from './components/QuestionForm.vue'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

export default {
  name: 'EditOnboardingQuestionPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    QuestionForm
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft
      }
    }
  },

  methods: {
    ...mapActions({
      updateQuestion: 'question/updateQuestion'
    }),

    async submit(form) {
      let formData = form.$data()

      formData.choices = formData.choices.map(choice => ({
        name: choice.name,
        resource_ids: choice.resources.map(r => r.id)
      }))

      formData.tips = formData.tips.map(tip => tip.name)
      formData.sub_questions = formData.sub_questions.map(
        question => question.name
      )

      formData.id = this.$route.params.id

      try {
        form.$busy = true

        await this.updateQuestion(formData)
        this.$router.replace({ name: 'onboarding-questions' })

        form.$busy = false
        form.$clearErrors()
      } catch (err) {
        if (err.response.status === 422) {
          form.$setErrors(err.response.data.errors)
        }
      } finally {
        form.$busy = false
      }
    }
  }
}
</script>
